/**
* Generated automatically at built-time (2024-11-11T15:12:02.569Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "48a62869-cce1-40eb-b691-3ee2a69755bc",templateKey: "sites/99-dbfe5f9a-beb7-4a11-98d2-b55e00ae301a"};