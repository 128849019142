/**
* Generated automatically at built-time (2024-11-11T15:12:02.637Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "utekok-svart",templateKey: "sites/99-956d438d-ca0c-44aa-a7f9-d09fecd41d76"};